import Card from "components/card";

const Widget = ({ icon, title, subtitle, subtitle2 }) => {

  return (
    <Card className="p-5 w-full dark:!bg-navy-800 dark:!text-white border border-gray-200 rounded-md shadow dark:!border-navy-700 shadow-sm">
      <div className="flex flex-row items-center space-x-5">
        <div>
          {icon}
        </div>
        <div className="flex flex-col text-left">
          <div className="text-xl font-bold">{title}</div>
          <div className="text-gray-500">{subtitle}</div>
          <div className="text-blue-500">{subtitle2}</div>
        </div>
      </div>
    </Card>
  );
};

export default Widget;
