/* eslint-disable */
import React from "react";
import DashIcon from "components/icons/DashIcon";
import { Link, useLocation } from "react-router-dom";

export function SidebarLinks(props) {
  let location = useLocation();
  const { routes } = props;
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  const createLinks = (routes) => {
    return routes
      .filter(route => !route.hidden) // Filter hanya yang route.hidden = false
      .map((route, index) => {
        if (
          route.layout === "/admin" ||
          route.layout === "/auth" ||
          route.layout === "/rtl"
        ) {
          return (
            <Link key={index} to={route.layout + "/" + route.path}>
              <div
                className={`${
                  activeRoute(route.path)
                    ? "relative p-3 bg-gray-100 dark:bg-gray-900 flex hover:cursor-pointer"
                    : "relative p-3 flex hover:cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-900"
                }`}
              >
                <li
                  className={`my-[3px] flex cursor-pointer items-center px-8`}
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path)
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path)
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px w-1 rounded-lg bg-brand-500 dark:bg-brand-400" style={{ height: "calc(100% - 2px)" }} />
                ) : null}
              </div>
            </Link>
          );
        }
      });
  };
  return createLinks(routes);
}

export default SidebarLinks;
