import Store from "views/store";
import Device from "views/device";
import Console from "views/console";
import Dashboard from "views/dashboard";
import StoreMenu from "views/store/storeMenu";
import DeviceShow from "views/deviceShow/detail";
import Dummy from "views/dummy";
import DummyShow from "views/dummyShow/detail";
import { MdOutlineHome, MdOutlineStore, MdOutlineDevices, MdOutlineMessage } from "react-icons/md";
// import User from "views/user";
// import Setting from "views/setting";

const routes = [
  {
    name: "Dashboard",
    path: "default",
    layout: "/admin",
    component: <Dashboard />,
    icon: <MdOutlineHome className="h-5 w-5" />,
    roles: [1, 2],
    hidden: false,
  },
  {
    name: "Store",
    path: "store",
    layout: "/admin",
    component: <Store/>,
    icon: <MdOutlineStore className="h-5 w-5" />,
    roles: [1, 2],
    hidden: false,
  },
  {
    name: "Store Menu",
    path: "store/:id",
    layout: "/admin",
    component: <StoreMenu />,
    icon: <MdOutlineDevices className="h-5 w-5" />,
    roles: [1, 2],
    hidden: true,
  },
  {
    name: "Device",
    path: "device",
    layout: "/admin",
    component: <Device/>,
    icon: <MdOutlineDevices className="h-5 w-5" />,
    roles: [1, 2],
    hidden: false,
  },
  {
    name: "Device Show",
    path: "device/:id",
    layout: "/admin",
    component: <DeviceShow />,
    icon: <MdOutlineDevices className="h-5 w-5" />,
    roles: [1, 2],
    hidden: true,
  },
  {
    name: "Device",
    path: "show",
    layout: "/admin",
    component: <Dummy/>,
    icon: <MdOutlineDevices className="h-5 w-5" />,
    roles: [1, 2],
    hidden: true,
  },
  {
    name: "Dummy Show",
    path: "show/:id",
    layout: "/admin",
    component: <DummyShow />,
    icon: <MdOutlineDevices className="h-5 w-5" />,
    roles: [1, 2],
    hidden: true,
  },
  {
    name: "Console",
    path: "console",
    layout: "/admin",
    component: <Console />,
    icon: <MdOutlineMessage className="h-5 w-5" />,
    roles: [1],
    hidden: false,
  },
  // {
  //   name: "User",
  //   path: "user",
  //   layout: "/admin",
  //   component: <User/>,
  //   icon: <MdOutlinePerson className="h-5 w-5"/>,
  // },
  // {
  //   name: "Setting",
  //   path: "setting",
  //   layout: "/admin",
  //   component: <Setting />,
  //   icon: <MdOutlineSettings className="h-5 w-5" />,
  // },
];

export default routes;
