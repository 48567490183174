import { useState, useEffect } from 'react';

const Toggle = ({ checked, onChange }) => {
  const [isOn, setIsOn] = useState(checked);

  useEffect(() => {
    setIsOn(checked);
  }, [checked]);

  const toggleSwitch = () => {
    if (checked) {
      onChange(false);
    } else {
      onChange(true);
    }
  };


  return (
    <div 
      onClick={toggleSwitch} 
      className={`relative inline-block w-12 h-6 cursor-pointer ${isOn ? 'bg-green-500' : 'bg-gray-500'} rounded-full transition-colors duration-300 ease-in-out`}
    >
      <div 
        className={`absolute top-0 left-0 w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${isOn ? 'translate-x-6' : 'translate-x-0'}`}
      ></div>
    </div>
  );
};

export default Toggle;
