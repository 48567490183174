import React from 'react';

const ActivityDetail = ({ activityDetail, formatDate, onBackToList }) => {
  return (
    <div className="dark:text-white">
      <div className="mt-2 p-2 border rounded bg-white border-gray-500 dark:bg-navy-800">
        <div className="p-2 mb-2 border rounded border-gray-500">
          <label>Activity Name:</label>
          <div>{activityDetail.activityName}</div>
        </div>
        <div className="p-2 mb-2 border rounded border-gray-500">
          <label>Store Name:</label>
          <div>{activityDetail.store}</div>
        </div>
        <div className="p-2 mb-2 border rounded border-gray-500">
          <label>Date:</label>
          <div>{formatDate(activityDetail.activityDate)}</div>
        </div>
        <div className="p-2 mb-2 border rounded border-gray-500">
          <label>Description:</label>
          <div>{activityDetail.description}</div>
        </div>
        <div className="flex justify-end">
          <button onClick={onBackToList} style={{ fontSize: '12px' }} className="p-2 rounded text-white bg-red-500 font-bold">BACK</button>
        </div>
      </div>
    </div>
  );
};

export default ActivityDetail;
