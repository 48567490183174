import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import ActivityDetail from './logDetail';
import DeviceBroadcastCreate from './logCreate';

const LogActivity = () => {
  const [activityLogs, setActivityLogs] = useState([
    { id: 1, activityName: 'Installation AC GREE', category: 'Maintenance', store: 'KFC MAXX Karawaci', activityDate: '2023-05-14', device: 'device1', description: 'Installation AC GREE 4 units' },
    { id: 2, activityName: 'Installation AC GREE', category: 'Maintenance', store: 'KFC MAXX Karawaci', activityDate: '2023-06-03', device: 'device2', description: 'Installation AC GREE 1 units' },
  ]);

  const [display, setDisplay] = useState('table');
  const [activityDetail, setActivityDetail] = useState(null);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');

  const columns = [
    { name: 'Date', selector: row => formatDate(row.activityDate), sortable: true },
    { name: 'Category', selector: row => row.category },
    { name: 'Name', selector: row => row.activityName },
    { name: 'Store', selector: row => row.store },
    {
      name: 'Actions', cell: row => (
        <div>
          <button className="px-4 py-2 rounded text-white bg-blue-500 hover:bg-opacity-90" onClick={() => handleShowActivity(row.id)}>Detail</button>
        </div>
      ), width: '400px'
    },
  ];

  const handleCreateActivity = () => setDisplay('create');
  const handleDeleteActivity = id => setActivityLogs(prevLogs => prevLogs.filter(activity => activity.id !== id));
  const handleShowActivity = id => {
    const selectedActivity = activityLogs.find(activity => activity.id === id);
    setActivityDetail(selectedActivity);
    setDisplay('detail');
  };
  const handleCloseCreateActivity = () => setDisplay('table');
  const addActivityToList = activity => {
    const uniqueId = generateUniqueId();
    const newActivity = { id: uniqueId, ...activity };
    setActivityLogs(prevLogs => [...prevLogs, newActivity]);
    setDisplay('table');
  };

  const generateUniqueId = () => Math.floor(Math.random() * 1000000);
  const formatDate = dateString => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      {display === 'table' && (
        <div className="mt-2 p-2 flex flex-col bg-white dark:bg-navy-800 dark:border-white rounded">
          <div className="flex justify-start mb-4">
            <button onClick={handleCreateActivity} className="py-2 px-4 rounded text-white bg-blue-500 hover:bg-opacity-90">Tambah Data</button>
          </div>
          <DataTable
            columns={columns}
            data={activityLogs}
            pagination
            highlightOnHover
          />
          <div className="flex justify-end mt-4">
            <button onClick={() => window.history.back()} className="p-2 rounded text-white bg-red-500 font-bold">BACK</button>
          </div>
        </div>
      )}
      {display === 'detail' && activityDetail && (
        <ActivityDetail
          formatDate={formatDate}
          activityDetail={activityDetail}
          onBackToList={() => setDisplay('table')}
        />
      )}
      {display === 'create' && (
        <DeviceBroadcastCreate
          onSubmit={addActivityToList}
          onClose={handleCloseCreateActivity}
          initialData={{ store: selectedStore, device: selectedDevice }}
        />
      )}
    </div>
  );
};

export default LogActivity;
