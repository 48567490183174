import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const DeviceBroadcastCreate = ({ onClose }) => {
  const [initialData, setInitialData] = useState({});
  const [activityName, setActivityName] = useState(initialData?.activityName || '');
  const [category, setCategory] = useState(initialData?.category || '1');
  const [description, setDescription] = useState(initialData?.description || '');
  const [files, setFiles] = useState([]);

  const handleSubmit = () => {
    const data = {
      activityName,
      category,
      description,
      files
    };

    console.log(data);
  };

  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    multiple: true
  });

  return (
    <form className="dark:text-white">
      <div className="mt-2 p-4 border rounded bg-white border-gray-500 dark:bg-navy-800">
        <div className='mb-4'>
          <label htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            className="px-4 py-2 mt-1 border w-full rounded-md border-gray-500 dark:bg-navy-800"
            defaultValue={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="1">Installation</option>
            <option value="2">Maintenance</option>
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="date">Date</label>
          <input type="date" id="date" name="date" className="px-4 py-2 mt-1 border w-full rounded-md border-gray-500 dark:bg-navy-800" />
        </div>
        <div className="mb-4">
          <label htmlFor="activityName" className="block text-sm">
            Activity Name
          </label>
          <input
            type="text"
            id="activityName"
            name="activityName"
            value={activityName}
            onChange={(e) => setActivityName(e.target.value)}
            className="px-4 py-2 mt-1 border w-full rounded-md border-gray-500 dark:bg-navy-800"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="px-4 py-2 mt-1 border w-full rounded-md border-gray-500 dark:bg-navy-800"
            rows="4"
          ></textarea>
        </div>
        <div className="mb-4">
          <label htmlFor="file">
            Upload Files (Word, Excel)
          </label>
          <div {...getRootProps({ className: 'dropzone' })} className="px-4 py-2 mt-1 border w-full rounded-md border-gray-500 dark:bg-navy-800 bg-gray-50 dark:bg-navy-700">
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <div className="mt-2">
            {files.length > 0 && (
              <ul>
                {files.map((file, index) => (
                  <li key={index} className="flex items-center justify-between">
                    <span>{file.name}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveFile(index)}
                      className="ml-2 px-2 py-1 rounded text-white bg-red-500 hover:bg-red-600"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="mt-10 flex justify-end">
          <button
            type="button"
            onClick={handleSubmit}
            className="px-4 py-2 rounded font-bold text-white bg-blue-500 hover:bg-opacity-90 mr-2"
          >
            Simpan
          </button>
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 rounded font-bold text-white bg-red-500 hover:bg-opacity-90 mr-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default DeviceBroadcastCreate;
