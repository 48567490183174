import PropTypes from 'prop-types';
import Toggle from 'components/toggle';
import { useState, useEffect } from 'react';
import { fetchWithJwt } from 'components/fetchWithJwt';

const Setting = ({ notifStatus, setNotifStatus, deviceId }) => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const handleChange = async () => {
    const confirmAction = window.confirm('Do you want to change the notification status?');
    if (!confirmAction) {
      return;
    }
    
    const newStatus = notifStatus === 1 ? 0 : 1;
    const formBody = new URLSearchParams();
    formBody.append('device_id', deviceId);
    formBody.append('type', 'wa');
    formBody.append('value', newStatus);

    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/notif`, {
        method: 'POST',
        body: formBody.toString(),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      
      if (response.ok) {
        setNotifStatus(newStatus);
      } else {
        console.error('Failed to update notification status');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="p-5 w-full dark:bg-navy-800 dark:text-white rounded-xl shadow dark:border-navy-700">
      <div className="text-xl font-bold">Notification</div>
      <div className="text-l text-gray-500">
        Turn On to get notification by WhatsApp if temperature exceeds 26°C
      </div>
      <Toggle checked={notifStatus} onChange={handleChange} />
    </div>
  );
};

Setting.propTypes = {
  notifStatus: PropTypes.number.isRequired,
  setNotifStatus: PropTypes.func.isRequired,
  deviceId: PropTypes.string.isRequired,
};

export default Setting;
