import { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "views/login";
import Password from "views/password";
import AdminLayout from "layouts/admin";

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) {
      navigate("/auth/login");
    }
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path="auth/login" element={<Login />} />
        <Route path="password" element={<Password />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </>
  );
};

export default App;
