import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logo from "../../assets/img/StoreKFC1.jpg";
import { fetchWithJwt } from 'components/fetchWithJwt';

const View = ({ setNotif }) => {
  const { id: storeId } = useParams();
  const [storeData, setStoreData] = useState({});
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    fetchData();
  }, [storeId]);

  const fetchData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/store/${storeId}`);
      const data = await response.json();
      setStoreData(data);
      setNotif(data.notif);
    } catch (error) {
      console.error("Failed to fetch store data:", error);
    }
  };

  return (
    <div className='text-navy-700 dark:text-white'>
      <div className='flex flex-wrap'>
        <div className='p-5 w-100'>
          <img src={logo} alt="logo" className="w-full" />
        </div>
        <div className='p-5'>
          <div className=''>
            <div className='font-bold text-base'>{storeData.nama}</div>
            <div className='text-sm'>{storeData.lokasi}</div>
          </div>
          <div className='mt-5'>
            <div className='text-gray-500'>Jumlah AC</div>
            <div className='font-bold'>{storeData.jumlah_ac} Unit</div>
          </div>
          <div className='mt-5'>
            <div className='text-gray-500'>Notifikasi WA</div>
            <div className='font-bold'>{storeData.notif === 1 ? 'Aktif' : 'Tidak Aktif'}</div>
          </div>
          <div className='mt-5'>
            <div className='text-gray-500'>Nomor Notifikasi Terdaftar</div>
            <div className='font-bold'>{storeData.total_number} Nomor</div>
          </div>
          <div className='mt-5'>
            <div className='text-gray-500'>Lokasi</div>
            <a target='_blank' rel='noopener noreferrer' className='text-blue-500' href='https://maps.app.goo.gl/17uRtdqznmJ32Tb36'>Lihat Maps</a>
          </div>
          <div className='mt-5'>
            <div className='text-gray-500'>Denah</div>
            <a target='_blank' rel='noopener noreferrer' className='text-blue-500' href='https://maps.app.goo.gl/17uRtdqznmJ32Tb36'>Lihat Denah</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
