import { useEffect } from 'react';
import DummyShow from 'views/dummyShow';
import { useNavigate } from 'react-router-dom';

const TabMenu = () => {
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem('userData'));
  if (!userData) return null;

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate("/auth/login");
    }
  }, [navigate]);

  return (
    <>
      <div className="mt-5"><DummyShow/></div>
    </>
  );
};

export default TabMenu;
