import logo from 'assets/img/nico.png';
import sunLogo from 'assets/img/sun.png';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuCard from 'views/dashboard/menuCard';
import offlineLogo from 'assets/img/offline.png';
import DeviceCard from 'views/dashboard/deviceIcon';
import { fetchWithJwt } from 'components/fetchWithJwt';

const Dashboard = () => {
  const navigate = useNavigate();
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const [dataDevice, setDataDevice] = useState([]);
  const [selectedDeviceName, setSelectedDeviceName] = useState('');
  const [companyId, setCompanyId] = useState(localStorage.getItem('company_id') || '');

  const handleButtonClick = () => {
    navigate('/admin/device');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/dashboard`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const dataResponse = await response.json();
        const filteredDevices = companyId ? dataResponse.data_alat.filter(device => device.id_company === parseInt(companyId)) : dataResponse.data_alat;
        setDataDevice(filteredDevices);
      } catch (error) {
        console.error('Error fetching device data:', error);
      }
    };
    fetchData();
    const handleStorageChange = () => {
      const newCompanyId = localStorage.getItem('company_id') || '';
      setCompanyId(newCompanyId);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [companyId, API_DOMAIN]);

  const countSensorOffline = dataDevice.filter(device => device.online === 0).length;
  const countHighTemperature = dataDevice.filter(device => device.temperature > 260 && device.notif === 1).length;
  const filteredDevices = dataDevice.filter(device => (selectedDeviceName ? device.name.toLowerCase().includes(selectedDeviceName.toLowerCase()) : true));

  return (
    <div>
      <div className="p-4 mt-4 bg-gradient-to-r from-brand-600 to-brand-500 rounded shadow-lg flex items-center">
        <img src={logo} alt="logo" className="w-14 h-14 object-cover mr-4 rounded"/>
        <div>
          <p className="text-2xl font-semibold text-white leading-tight">PT. NICO URBAN INDONESIA</p>
          <p className="text-lg font-medium text-white opacity-90">Air System Expert</p>
        </div>
      </div>
      <div className="mt-2 rounded p-2 grid h-full grid-cols-1 gap-2 md:grid-cols-1 bg-white dark:bg-navy-900">
        <button onClick={handleButtonClick}>
          <MenuCard title={"Device Status Offline"} subtitle={countSensorOffline + " Device"} icon={<img className="w-12" src={offlineLogo} alt="Offline Device Icon"/>}/>
        </button>
        <button className="mt-2" onClick={handleButtonClick}>
          <MenuCard title={"Device Status Temperature Abnormal"} subtitle={countHighTemperature + " Device"} icon={<img src={sunLogo} className="w-12" alt="Abnormal Device Icon"/>}/>
        </button>
      </div>
      <div className="rounded p-2 grid h-full grid-cols-1 gap-2 md:grid-cols-1 bg-white dark:bg-navy-900">
        <input type="text" value={selectedDeviceName} placeholder="Search Device Name" onChange={(e) => setSelectedDeviceName(e.target.value)} className="border block w-full rounded p-2 text-sm font-medium text-navy-700 dark:bg-navy-900 dark:text-white"/>    
        <div className="mt-2 grid gap-4 md:grid-cols-3">
          {filteredDevices.length > 0 ? (
            filteredDevices.map((item) => (<DeviceCard key={item.device_id} title={item.name} status={item.online} icon={<img className="w-14" alt="Device Icon" src={item.icon}/>}/>))
          ) : (
            <p>No Devices Available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
