import React from 'react';
import PropTypes from 'prop-types';

const ReusableTable = ({ columns, data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white dark:bg-navy-900">
        <thead>
          <tr className="w-full">
            {columns.map((column) => (
              <th
                key={column.field}
                className="py-2 px-4 border-b dark:border-navy-700 text-left"
              >
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="w-full">
              {columns.map((column) => (
                <td
                  key={column.field}
                  className="py-2 px-4 border-b dark:border-navy-700"
                >
                  {row[column.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

ReusableTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ReusableTable;
