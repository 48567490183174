const Footer = () => {
  return (
    <div>
      <h5 className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        <p className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
          Copyright © {1900 + new Date().getYear()} NICO URBAN INDONESIA
        </p>
      </h5>
    </div>
  );
};

export default Footer;
