import React from "react";
import routes from "routes.js";
import Sidebar from "components/menu";
import Navbar from "components/header";
import Footer from "components/footer";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  React.useEffect(() => {
    window.addEventListener("resize", () => window.innerWidth < 1200 ? setOpen(false) : setOpen(true))
  }, []);

  React.useEffect(() => {
    const handleResize = () => {setOpen(window.innerWidth >= 1200)};
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key}/>
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)}/>
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}>
          <div className="h-full">
            <Navbar {...rest} brandText={currentRoute} onOpenSidenav={() => setOpen(true)} secondary={getActiveNavbar(routes)} logoText={"ISEMS - Internet Of Things"}/>
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<Navigate to="/admin/default" replace/>}/>
              </Routes>
            </div>
            <div className="p-3"><Footer/></div>
          </div>
        </main>
      </div>
    </div>
  );
}
