import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import DeviceControl from 'views/deviceControl';
import DeviceShow from 'views/deviceShow';
import DeviceScene from 'views/deviceScene';

const TabMenu = () => {
  const [activeTab, setActiveTab] = useState('Tab1');
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate("/auth/login");
    }
  }, [navigate]);

  const userData = JSON.parse(localStorage.getItem('userData'));
  if (!userData) return null;  // Menambahkan pengecekan userData sebelum melanjutkan

  const role = userData.role_id;
  console.log(role);

  const handleTabClick = (tab) => {
    if ((tab === 'Tab2' || tab === 'Tab3') && role !== 1) {
      window.alert('You don\'t have permission to access this page');
      return;
    }
    setActiveTab(tab);
  };

  return (
    <>
      <div className="w-full max-w-md mx-auto">
        <div className="flex flex-wrap lg:justify-start">
          <button
            className={`px-4 py-2 w-1/3 text-center ${activeTab === 'Tab1' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            onClick={() => handleTabClick('Tab1')}
          >
            Device Show
          </button>
          <button
            className={`px-4 py-2 w-1/3 text-center ${(activeTab === 'Tab2') ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            onClick={() => handleTabClick('Tab2')}
          >
            Device Control
          </button>
          <button
            className={`px-4 py-2 w-1/3 text-center ${activeTab === 'Tab3' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            onClick={() => handleTabClick('Tab3')}
          >
            Broadcast
          </button>
        </div>
      </div>
      <div className="mt-5">
        {activeTab === 'Tab1' && <DeviceShow />}
        {activeTab === 'Tab2' && role === 1 && <DeviceControl />}
        {activeTab === 'Tab3' && role === 1 && <DeviceScene />}
      </div>
    </>
  );
};

export default TabMenu;
