import moment from 'moment';
import { useEffect, useState } from 'react';
import { MdCreate, MdDelete } from "react-icons/md";
import { fetchWithJwt } from 'components/fetchWithJwt';
import DeviceBroadcastCreate from 'views/deviceScene/deviceSceneCreate';

const DeviceBroadcast = () => {
  const getModeText = (modeValue) => {
    if (modeValue === null) {
      return "";
    }
    switch (modeValue) {
      case 0:
        return "COLD";
      case 1:
        return "HOT";
      case 2:
        return "AUTO";
      case 3:
        return "SPEED";
      case 4:
        return "DEHUMY";
      default:
        return "";
    }
  };

  const getWindText = (windValue) => {
    if (windValue === null) {
      return "";
    }
    switch (windValue) {
      case 0:
        return "AUTO";
      case 1:
        return "LOW";
      case 2:
        return "MIDDLE";
      case 3:
        return "HIGH";
      default:
        return "";
    }
  };

  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const DisplayMode = { TABLE: 'table', DETAIL: 'detail' };

  const [statusMap, setStatusMap] = useState({});
  const [deviceList, setDeviceList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [broadcastList, setBroadcastList] = useState([]);
  const [display, setDisplay] = useState(DisplayMode.TABLE);
  const [editBroadcastData, setEditBroadcastData] = useState(null);
  const [showCreateBroadcast, setShowCreateBroadcast] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('company_id') || "");

  const filteredBroadcasts = broadcastList
  .filter((broadcast) => broadcast.nama.toLowerCase().includes(searchTerm.toLowerCase()))
  .filter((broadcast) => !selectedCompany || broadcast.id_company === parseInt(selectedCompany))
  .sort((a, b) => a.nama.localeCompare(b.nama));

  const generateUniqueId = () => Math.floor(Math.random() * 1000000);
  const handleSearchTermChange = (event) => setSearchTerm(event.target.value);

  const handleCreateBroadcast = () => {
    setDisplay(DisplayMode.DETAIL);
    setEditBroadcastData(null);
  };

  const getDeviceName = (id) => {
    const device = deviceList.find((device) => device.device_id === id);
    return device ? device.name : '';
  };

  const addBroadcastToList = (broadcast) => {
    const uniqueId = generateUniqueId();
    setBroadcastList((prevList) => [...prevList, { ...broadcast, id: uniqueId, isActive: true }]);
  };

  const editBroadcast = (id) => {
    const broadcastToEdit = broadcastList.find((broadcast) => broadcast.id === id);
    setEditBroadcastData(broadcastToEdit);
    setDisplay(DisplayMode.DETAIL);
  };

  const handleEditSubmit = (editedBroadcast) => {
    setBroadcastList((prevList) => prevList.map((broadcast) => (broadcast.id === editedBroadcast.id ? editedBroadcast : broadcast)));
    setShowCreateBroadcast(false);
    setEditBroadcastData(null);
  };

  const renderStatusIcon = (online) => {
    return online ? (
      <div className="">ON</div>
    ) : (
      <div className="">OFF</div>
    );
  };
  

  const fetchDevices = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/show_control`);
      if (!response.ok) throw new Error('Failed to fetch data');
      const data = await response.json();
      setDeviceList(data.data_alat);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteBroadcast = async (id) => {
    try {
      const confirmed = window.confirm(`Are you sure you want to delete this broadcast?`);
      if (!confirmed) return;
      const response = await fetchWithJwt(`${API_DOMAIN}/broadcast/delete/${id}`, { method: 'DELETE' });
      if (!response.ok) throw new Error('Failed to delete broadcast');
      alert('Success to delete broadcast.');
      setBroadcastList((prevList) => prevList.filter((broadcast) => broadcast.id !== id));
    } catch (error) {
      console.error('Error deleting broadcast:', error.message);
    }
  };

  const fetchBroadcasts = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/broadcast`);
      if (!response.ok) throw new Error('Failed to fetch data');
      const data = await response.json();
      const filteredData = selectedCompany === "" ? data : data.filter(broadcast => broadcast.id_company === parseInt(selectedCompany));
      setBroadcastList(filteredData);
      const newStatusMap = {};
      filteredData.forEach((broadcast) => { newStatusMap[broadcast.id] = broadcast.status === 1 ? true : false });
      setStatusMap(newStatusMap);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleBroadcast = async (id) => {
    try {
      const currentStatus = statusMap[id];
      const updatedStatus = !currentStatus;
      const confirmed = window.confirm(`Are you sure you want to update status "${updatedStatus ? 'ON' : 'OFF'}" the broadcast?`);
      if (!confirmed) return;
      const response = await fetchWithJwt(`${API_DOMAIN}/broadcast/update/${id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status: updatedStatus ? 1 : 0 })
      });
      if (!response.ok) throw new Error('Failed to update broadcast status');
      setStatusMap((prevMap) => ({ ...prevMap, [id]: updatedStatus }));
      alert(`Success to update status "${updatedStatus ? 'ON' : 'OFF'}" broadcast.`);
    } catch (error) {
      alert('Failed to update status "ON/OFF" broadcast.');
    }
  };

  useEffect(() => {
    fetchBroadcasts();
    fetchDevices();
  }, [selectedCompany]);

  useEffect(() => {
    const handleStorageChange = () => {
      setSelectedCompany(localStorage.getItem('company_id') || "");
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <div>
      {display === DisplayMode.TABLE && (
        <div className="dark:text-white">
          <div className="mb-5 flex justify-end">
            <button onClick={handleCreateBroadcast} className="px-4 py-2 rounded-md hover:bg-opacity-90 text-white bg-brand-600">+ CREATE BROADCAST</button>
          </div>
          <div className="mt-2 mb-2 flex">
            <input type="text" value={searchTerm} style={{ fontSize: '12px' }} onChange={handleSearchTermChange} placeholder="Search by Broadcast Name ..." className="p-2 border rounded flex-grow border-gray-500 dark:bg-navy-800"/>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white dark:bg-navy-800 shadow rounded-lg">
              <thead>
                <tr className="bg-gray-200 dark:bg-navy-700 dark:text-gray-300 uppercase text-sm leading-normal">
                  {/* <th className="py-3 px-6 text-left">Nama</th> */}
                  <th className="py-3 px-6 text-left">Store</th>
                  <th className="py-3 px-6 text-left">Time</th>
                  {/* <th className="py-3 px-6 text-left">Status</th> */}
                  <th className="py-3 px-6 text-left">Temp</th>
                  <th className="py-3 px-6 text-left">Mode</th>
                  <th className="py-3 px-6 text-left">Fan</th>
                  <th className="py-3 px-6 text-left">Devices</th>
                  <th className="py-3 px-6 text-right">Actions</th>
                </tr>
              </thead>
              <tbody className="dark:text-gray-400 text-sm  ">
                {filteredBroadcasts.map((broadcast, index) => (
                  <tr key={broadcast.id} className="border-b border-gray-200 dark:border-navy-700 hover:bg-gray-100 dark:hover:bg-navy-700">
                    {/* <td className="py-3 px-6 text-left whitespace-nowrap ">{broadcast.nama}</td> */}
                    <td className="py-3 px-6 text-left ">{broadcast.nama_store}</td>
                    <td className="py-3 px-6 text-left font-bold">
                      {moment(broadcast.jam_mulai, 'HH:mm:ss').format('HH:mm')} - {moment(broadcast.jam_selesai, 'HH:mm:ss').format('HH:mm')}
                    </td>
                    {/* <td className="py-3 px-6 text-left ">{renderStatusIcon(statusMap[broadcast.id])}</td> */}
                    <td className="py-3 px-6 text-left ">{broadcast.temp}°C</td>
                    <td className="py-3 px-6 text-left ">{getModeText(broadcast.mode)}</td>
                    <td className="py-3 px-6 text-left ">{getWindText(broadcast.fan)}</td>
                    <td className="py-3 px-6 text-left">
                      <div className="flex flex-wrap gap-1">
                        {JSON.parse(broadcast.device_ids).map((device_id) => (
                          <div key={device_id} className=" bg-gray-600 text-white px-2 rounded-full">
                            {getDeviceName(device_id)}
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="py-3 px-6 text-right">
                      <div className="flex justify-end space-x-2">
                        <button onClick={() => toggleBroadcast(broadcast.id)} className={`px-4 py-2 rounded-md hover:bg-opacity-90 text-white ${statusMap[broadcast.id] ? 'bg-green-500' : 'bg-red-500'}`}>{statusMap[broadcast.id] ? 'ON' : 'OFF'}</button>
                        <button onClick={() => editBroadcast(broadcast.id)} className="px-4 py-2 rounded-md hover:bg-opacity-90 text-white bg-brand-600"><MdCreate /></button>
                        <button onClick={() => deleteBroadcast(broadcast.id)} className="px-4 py-2 rounded-md hover:bg-opacity-90 text-white bg-red-500"><MdDelete /></button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {showCreateBroadcast && <DeviceBroadcastCreate onClose={() => setShowCreateBroadcast(false)} onCreate={addBroadcastToList} />}
        </div>
      )}
      {display === DisplayMode.DETAIL && (
        <DeviceBroadcastCreate initialData={editBroadcastData} onClose={() => setDisplay(DisplayMode.TABLE)} onSubmit={editBroadcastData ? handleEditSubmit : addBroadcastToList}/>
      )}
    </div>
  );
};

export default DeviceBroadcast;
