import PropTypes from 'prop-types';
import Toggle from "components/toggle";
import { useState, useEffect } from 'react';
import { fetchWithJwt } from "components/fetchWithJwt";

const Setting = ({ notifStatus, deviceId }) => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const [checked, setChecked] = useState(notifStatus === 1);

  const handleChange = async () => {
    const action = checked ? 'off' : 'on';
    const confirmAction = window.confirm(`You want to turn ${action} the notification?`);
    if (!confirmAction) {
      return;
    }
    const value = checked ? 0 : 1;
    const type = 'wa';
    const formBody = new URLSearchParams();
    formBody.append('device_id', deviceId);
    formBody.append('type', type);
    formBody.append('value', value);
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/notif`, {
        method: 'POST',
        body: formBody.toString(),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      });
      if (response.ok) {
        setChecked(value === 1);
      } else {
        console.error('Failed to update notification status');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    setChecked(notifStatus === 1);
  }, [notifStatus]);

  return (
    <div className="p-5 w-full dark:bg-navy-800 dark:text-white rounded-xl shadow dark:border-navy-700">
      <div className="text-xl font-bold">Notification</div>
      <div className="text-l text-gray-500">Turn On to get notification by WhatsApp if temperature more than 26°C</div>
      <Toggle checked={checked} onChange={handleChange}/>
    </div>
  );
};

Setting.propTypes = {
  notifStatus: PropTypes.number.isRequired,
  deviceId: PropTypes.string.isRequired,
};

export default Setting;
