import routes from "routes.js";
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import image from "assets/img/nico.png";
import { useState, useEffect } from 'react';
import { fetchWithJwt } from 'components/fetchWithJwt';

const Sidebar = ({ open, onClose }) => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const [dataStore, setDataStore] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [roleId, setRoleId] = useState('default_role_id');
  const [selectedCompany, setSelectedCompany] = useState('');

  const filterRoutesByRole = () => {
    return routes.filter(route => {
      if (roleId === 1) {
        return true;
      } else if (roleId === 2) {
        return !route.path.includes('console');
      }
      return false;
    });
  };

  const selectCompanyHandle = async (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    setSelectedStore('');
    if (companyId === "") {
      localStorage.removeItem('company_id');
      setDataStore([]);
    } else {
      localStorage.setItem('company_id', companyId);
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/company/store/${companyId}`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setDataStore(data);
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    }
    window.dispatchEvent(new Event('storage'));
  };

  useEffect(() => {
    const storedCompanyId = localStorage.getItem('company_id');
    if (storedCompanyId) {
      setSelectedCompany(storedCompanyId);
      const fetchStoreData = async () => {
        try {
          const response = await fetchWithJwt(`${API_DOMAIN}/company/store/${storedCompanyId}`);
          if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
          const data = await response.json();
          setDataStore(data);
        } catch (error) {
          console.error('Error fetching store data:', error);
        }
      };
      fetchStoreData();
    }
  }, []);

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    try {
      const parsedData = JSON.parse(userData);
      const userRoleId = parsedData.role_id;
      setRoleId(userRoleId);
    } catch (error) {
      console.error('Error parsing user data:', error);
      setRoleId('default_role_id');
    }
  }, []);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/company`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setCompanyData(data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };
    fetchCompanyData();
  }, []);

  return (
    <div className={`sm:none duration-175 w-72 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"}`}>
      <span onClick={onClose} className="absolute top-4 right-4 block cursor-pointer xl:hidden"><HiX/></span> 
      <div className={` items-center`} style={{ width: '100%', height: '150px', display: 'flex', justifyContent: 'center' }}>
        <div><img src={image} alt="I-SEMS logo" style={{ width: '50px' }}/></div>
      </div>
      {/* <h3 class="mb-4 ml-9 font-bold text-bodydark2">Select Filter</h3> */}
      <div className="flex flex-col space-y-5 px-5 justify-center">
        <select value={selectedCompany} onChange={selectCompanyHandle} className="w-full shadow-lg p-2 rounded text-navy-700 dark:bg-navy-900 dark:text-white">

          <option value="">All Companies</option>
          {companyData.length > 0 ? (
            companyData.map((item) => (<option key={item.id} value={item.id}>{item.nama}</option>))
          ) : (
            <option value="">No Companies Available</option>
          )}
        </select>
        <select 
          value={selectedStore} 
          disabled={!selectedCompany} onChange={(e) => setSelectedStore(e.target.value)} className="w-full shadow-lg p-2 rounded text-navy-700 dark:bg-navy-900 dark:text-white">
          <option value="">All Stores</option>
          {dataStore.length > 0 ? (
            dataStore.map((item) => (<option key={item.id} value={item.id}>{item.nama}</option>))
          ) : (
            <option value="">No Stores Available</option>
          )}
        </select>
      </div>
      
      <h3 class="mb-4 mt-10 ml-9 font-bold text-bodydark2">Menu</h3>
      <div className="h-px bg-gray-300 dark:bg-white/30 overflow-hidden"/>
      <ul className="mb-auto"><Links routes={filterRoutesByRole()}/></ul>
    </div>
  );
};

export default Sidebar;
