import { useState } from 'react';
import View from 'views/store/view';
import History from 'views/store/log';
import Setting from 'views/store/setting';
import { MdHome, MdSettings } from "react-icons/md";

const StoreMenu = () => {
  const [activeTab, setActiveTab] = useState('detail');
  const [notif, setNotif] = useState(null);

  return (
    <>
      <div className="flex items-center justify-center mt-5">
        <button onClick={() => setActiveTab('detail')} className={`text-center flex items-center justify-center gap-1 w-full py-2 ${activeTab === 'detail' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>
          <MdHome/> Store
        </button>
        <button onClick={() => setActiveTab('setting')} className={`text-center flex items-center justify-center gap-1 w-full py-2 ${activeTab === 'setting' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>
          <MdSettings/> Setting
        </button>
        {/* <button onClick={() => setActiveTab('data_ac')} className={`text-center flex items-center justify-center gap-1 w-full py-2 ${activeTab === 'data_ac' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>
          <MdHeatPump/> Data AC
        </button>
        <button onClick={() => setActiveTab('sensor')} className={`text-center flex items-center justify-center gap-1 w-full py-2 ${activeTab === 'sensor' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>
          <MdControlCamera /> Sensor Temperature
        </button>
        <button onClick={() => setActiveTab('history')} className={`text-center flex items-center justify-center gap-1 w-full py-2 ${activeTab === 'history' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>
          <MdHistory/> History
        </button> */}
      </div>
      <div className="mt-5">
        {activeTab === 'detail' && <View setNotif={setNotif} />}
        {activeTab === 'history' && <History />}
        {activeTab === 'setting' && <Setting notifStatus={notif} />}
      </div>
    </>
  );
};

export default StoreMenu;
