import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { fetchWithJwt } from "components/fetchWithJwt";
import { MdThermostat, MdWaterDrop, MdBatteryFull, MdOutlineRemoveRedEye } from "react-icons/md";

const DeviceShow = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const [sensorData, setSensorData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSensorData, setFilteredSensorData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('company_id') || "");

  const handleStorageChange = () => {
    setSelectedCompany(localStorage.getItem('company_id') || "");
  };

  const filterSensorData = () => {
    let filteredData = sensorData;
    if (searchTerm.trim() !== "") {filteredData = filteredData.filter(sensor => sensor.name.toLowerCase().includes(searchTerm.toLowerCase()));}
    setFilteredSensorData(filteredData);
  };

  const fetchData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/show`);
      if (!response.ok) throw new Error(`Failed to fetch data: ${response.status} - ${response.statusText}`);
      const data = await response.json();
      const filteredData = selectedCompany === "" ? data.data_alat : data.data_alat.filter(sensor => sensor.id_company === parseInt(selectedCompany));
      setSensorData(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCompany]);

  useEffect(() => {
    filterSensorData();
  }, [sensorData, searchTerm]);

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <div>
      <div className="dark:text-white">
        <div className="mt-2 flex">
          <input type="text" value={searchTerm} style={{ fontSize: '12px' }} placeholder="Search by Device Name ..." onChange={(e) => setSearchTerm(e.target.value)} className="p-2 border rounded flex-grow border-gray-500 dark:bg-navy-800"/>
        </div>
        <div className="flex flex-wrap -m-1 mt-2">
          {filteredSensorData.map((sensor, index) => (
            <div key={index} className="w-full p-1 sm:w-1/2 md:w-1/3 lg:w-1/4">
              <div className="p-5 flex flex-col gap-3 bg-white shadow dark:bg-navy-800 rounded-lg">
                <div className='flex justify-between flex-row gap-3'>
                  <div>
                    <div className="text-gray-500 text-sm">{sensor.store}</div>
                    <div className='font-bold'>{sensor.name}</div>
                  </div>
                  <div className='text-right'>
                    <div className="text-gray-500 text-sm">Status</div>
                    <div className="font-bold">{renderStatusIcon(sensor.online)}</div>
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className={`flex items-center ${sensor.status.find(stat => stat.code === 'va_temperature').value > 260 ? 'text-red-500' : ''}`}>
                    <div><MdThermostat className="h-5 w-5"/></div>
                    <div>{sensor.status.find(stat => stat.code === 'va_temperature').value / 10}°C</div>
                  </div>
                  <div className="flex items-center">
                    <div><MdWaterDrop className="h-5 w-5"/></div>
                    <div>{sensor.status.find(stat => stat.code === 'va_humidity').value / 10}%</div>
                  </div>
                  <div className="flex items-center">
                    <div><MdBatteryFull className="h-5 w-5"/></div>
                    <div>{sensor.status.find(stat => stat.code === 'battery_percentage').value}%</div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Link to={`/admin/device/${sensor.device_id}`}>
                    <button className="px-4 py-2 rounded-md flex items-center gap-2 hover:bg-opacity-90 text-white bg-brand-600">
                      <MdOutlineRemoveRedEye /> Detail
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const renderStatusIcon = (isOnline) => {
  return isOnline ? 'Online' : 'Offline';
};

export default DeviceShow;
