import Card from "components/card";

const Widget = ({ icon, title, status }) => {
  const getStatus = (status) => {
    return status === 1 ? "Online" : "Offline";
  };

  return (
    <Card extra="!flex-row flex-grow items-center rounded">
      <div className="ml-[20px] flex h-[100px] w-auto flex-row items-center">
        <div className="rounded bg-lightPrimary p-2 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">{icon}</span>
        </div>
      </div>
      <div className="h-50 ml-5 flex w-auto flex-col justify-center">
        <p className="text-l font-bold text-navy-700 dark:text-white">{title}</p>
        <p className={`font-dm text-sm font-medium text-gray-600 ${status === 1 ? 'text-green-500' : 'text-yellow-500'}`}>{getStatus(status)}</p>
      </div>
    </Card>
  );
};

export default Widget;
