import './checkbox.css';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { fetchWithJwt } from 'components/fetchWithJwt';

const storeDetails = {
  1: { name: 'Kantor Palem', companyId: 1 },
  2: { name: 'MAXX KARAWACI', companyId: 2 },
};

const devicesByStore = {
  1: [
    { id: 'eb92bab38b810c971fo6kg', name: 'Remote A (AC R. Meeting)' },
    { id: 'eb8ff9ca9156d99318txsv', name: 'Remote B (AC R. IT)' },
  ],
  2: [
    { id: 'ebf044c5ab0f602fbbgtjg', name: 'Remote A (AC 1 3)' },
    { id: 'ebc3d37ffdb497c32aaeqp', name: 'Remote B (AC 2 4)' },
    { id: 'eb7daaba606c7a4210blqs', name: 'Remote C (AC 5)' },
  ],
};

const DeviceBroadcastCreate = ({ onSubmit, onClose, initialData }) => {
  const isEditMode = !!initialData;
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const [broadcastDetails, setBroadcastDetails] = useState({
    status: 1,
    nama: '',
    deskripsi: '',
    device_ids: [],
    jam_mulai: '',
    jam_selesai: '',
    temp: null,
    mode: null,
    fan: null,
    id_store: '',
    nama_store: '',
    id_company: '',
  });

  const handleStoreChange = (e) => {
    const selectedStoreId = e.target.value;
    const store = storeDetails[selectedStoreId];
    handleInputChange('id_store', selectedStoreId);
    handleInputChange('nama_store', store?.name || '');
    handleInputChange('id_company', store?.companyId || '');
  };

  const handleInputChange = (name, value) => {
    const processedValue = name === 'mode' || name === 'fan' ? parseInt(value, 10) : value;
    setBroadcastDetails((prevDetails) => ({
      ...prevDetails,
      [name]: processedValue,
    }));
  };

  const isSubmitDisabled = !Object.values(broadcastDetails).every((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (value === null || value === '') {
      return false;
    }
    return true;
  }) || broadcastDetails.device_ids.length === 0;

  const handleDeviceChange = (device_id) => {
    setBroadcastDetails((prevDetails) => {
      const updatedDevices = prevDetails.device_ids.includes(device_id)
        ? prevDetails.device_ids.filter((d) => d !== device_id)
        : [...prevDetails.device_ids, device_id];
      return {
        ...prevDetails,
        device_ids: updatedDevices,
      };
    });
  };

  const increment = (name) => {
    setBroadcastDetails((prevDetails) => {
      if (name === 'temp') {
        const newValue = prevDetails[name] === null ? 16 : Math.min(prevDetails[name] + 1, 30);
        return {
          ...prevDetails,
          [name]: newValue,
        };
      } else if (name === 'mode') {
        return {
          ...prevDetails,
          [name]: prevDetails[name] === null ? 0 : (prevDetails[name] + 1) % 5,
        };
      } else if (name === 'fan') {
        return {
          ...prevDetails,
          [name]: prevDetails[name] === null ? 0 : (prevDetails[name] + 1) % 4,
        };
      }
      return prevDetails;
    });
  };
  
  const decrement = (name) => {
    setBroadcastDetails((prevDetails) => {
      if (name === 'temp') {
        const newValue = prevDetails[name] === null ? 16 : Math.max(prevDetails[name] - 1, 16);
        return {
          ...prevDetails,
          [name]: newValue,
        };
      } else if (name === 'mode') {
        return {
          ...prevDetails,
          [name]: prevDetails[name] === null ? 4 : (prevDetails[name] - 1 + 5) % 5,
        };
      } else if (name === 'fan') {
        return {
          ...prevDetails,
          [name]: prevDetails[name] === null ? 3 : (prevDetails[name] - 1 + 4) % 4,
        };
      }
      return prevDetails;
    });
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isSubmitDisabled) {
      const { jam_mulai, jam_selesai, id } = broadcastDetails;
      const confirmMessage = isEditMode ? 'Are you sure you want to update the broadcast?' : 'Are you sure you want to save the broadcast?';
      if (jam_mulai !== jam_selesai) {
        const confirmed = window.confirm(confirmMessage);
        if (!confirmed) {
          return;
        }
        try {
          const processedBroadcastDetails = {
            ...broadcastDetails,
            mode: parseInt(broadcastDetails.mode),
            fan: parseInt(broadcastDetails.fan),
            device_ids: JSON.stringify(broadcastDetails.device_ids),
          };
          let response;
          if (isEditMode) {
            response = await fetchWithJwt(`${API_DOMAIN}/broadcast/update/${id}`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(processedBroadcastDetails),
            });
          } else {
            response = await fetchWithJwt(`${API_DOMAIN}/broadcast/create`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(processedBroadcastDetails),
            });
          }
          if (response.ok) {
            alert('Success to save broadcast.');
            onSubmit(processedBroadcastDetails);
            onClose();
          } else {
            alert('Failed to save broadcast.');
          }
        } catch (error) {
          alert('Failed to update broadcast.');
        }
      } else {
        alert('Start time and end time cannot be the same');
      }
    }
  };
  
  useEffect(() => {
    if (!isEditMode) {
      setBroadcastDetails({
        status: 1,
        nama: '',
        deskripsi: '',
        id_store: '',
        nama_store: '',
        id_company: '',
        device_ids: [],
        jam_mulai: '',
        jam_selesai: '',
        temp: null,
        mode: null,
        fan: null,
      });
    } else {
      const deviceList = JSON.parse(initialData.device_ids);
      setBroadcastDetails({
        id: initialData.id,
        nama: initialData.nama,
        deskripsi: initialData.deskripsi,
        id_store: initialData.id_store,
        nama_store: storeDetails[initialData.id_store]?.name || '',
        id_company: storeDetails[initialData.id_store]?.companyId || '',
        device_ids: deviceList,
        jam_mulai: initialData.jam_mulai,
        jam_selesai: initialData.jam_selesai,
        temp: initialData.temp !== null ? initialData.temp.toString() : null,
        mode: initialData.mode !== null ? initialData.mode.toString() : null,
        fan: initialData.fan !== null ? initialData.fan.toString() : null,
      });
    }
  }, [initialData, isEditMode]);

  const devices = devicesByStore[broadcastDetails.id_store] || [];

  return (
    <form onSubmit={handleSubmit} className="dark:text-white">
      <div className="mt-2 p-4 border rounded bg-white border-gray-500 dark:bg-navy-800">
        <div className="mb-4">
          <label htmlFor="nama" className="block text-sm">Broadcast Name:</label>
          <input required id="nama" type="text" name="nama" aria-label="Nama" style={{ color: 'black' }} value={broadcastDetails.nama} className="p-2 border w-full rounded border-gray-300" onChange={(e) => handleInputChange('nama', e.target.value)}/>
        </div>
        <div className="mb-4">
          <label htmlFor="deskripsi" className="block text-sm">Description:</label>
          <input required type="text" id="deskripsi" name="deskripsi" aria-label="Deskripsi" style={{ color: 'black' }} value={broadcastDetails.deskripsi} className="p-2 border w-full rounded border-gray-300" onChange={(e) => handleInputChange('deskripsi', e.target.value)}/>
        </div>
        <div className="mb-4">
          <label htmlFor="id_store" className="block text-sm">Store:</label>
          <select id="id_store" name="id_store" disabled={isEditMode} onChange={handleStoreChange} value={broadcastDetails.id_store} className="p-2 border w-full rounded border-gray-300">
            <option value="">Select Store</option>
            {Object.entries(storeDetails).map(([id, { name }]) => (<option key={id} value={id}>{name}</option>))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm">Devices:</label>
          {devices.map(device => (
            <div key={device.id} className="mr-4 mb-2">
              <input type="checkbox" name="device_ids" id={device.id} value={device.id} onChange={() => handleDeviceChange(device.id)} checked={broadcastDetails.device_ids.includes(device.id)}/>
              <label htmlFor={device.id} className="ml-2">{device.name}</label>
            </div>
          ))}
        </div>
        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label htmlFor="jam_mulai" className="block text-sm">Start Time:</label>
            <input required type="time" id="jam_mulai" name="jam_mulai" aria-label="Start Time" style={{ color: 'black' }} value={broadcastDetails.jam_mulai} className="p-2 border w-full rounded border-gray-300" onChange={(e) => handleInputChange('jam_mulai', e.target.value)}/>
          </div>
          <div className="w-1/2">
            <label htmlFor="jam_selesai" className="block text-sm">End Time:</label>
            <input required type="time" id="jam_selesai" name="jam_selesai" aria-label="End Time" style={{ color: 'black' }} value={broadcastDetails.jam_selesai} className="p-2 border w-full rounded border-gray-300" onChange={(e) => handleInputChange('jam_selesai', e.target.value)}/>
          </div>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <div>
            <label htmlFor="temp" className="block text-sm">Temperature:</label>
            <div className="text-l">
              <button type="button" style={{ fontSize: '14px' }} onClick={() => decrement('temp')} className="p-1 mr-2 rounded font-bold text-white bg-brand-600">&lt;</button>
                {broadcastDetails.temp !== null ? broadcastDetails.temp : ''}
              <button type="button" style={{ fontSize: '14px' }} onClick={() => increment('temp')} className="p-1 ml-2 rounded font-bold text-white bg-brand-600">&gt;</button>
            </div>
          </div>
          <div>
            <label htmlFor="mode" className="block text-sm">Mode:</label>
            <select id="mode" name="mode" aria-label="Mode" style={{ color: 'black' }} value={broadcastDetails.mode} className="p-2 border rounded border-gray-300" onChange={(e) => handleInputChange('mode', e.target.value)}>
              <option value="">Select Mode</option>
              <option value="0">COLD</option>
              <option value="1">HOT</option>
              <option value="2">AUTO</option>
              <option value="3">SPEED</option>
              <option value="4">DEHUMY</option>
            </select>
          </div>
          <div>
            <label htmlFor="fan" className="block text-sm">Fan Speed:</label>
            <select id="fan" name="fan" aria-label="Fan Speed" style={{ color: 'black' }} value={broadcastDetails.fan} className="p-2 border rounded border-gray-300" onChange={(e) => handleInputChange('fan', e.target.value)}>
              <option value="">Select Fan Speed</option>
              <option value="0">AUTO</option>
              <option value="1">LOW</option>
              <option value="2">MIDDLE</option>
              <option value="3">HIGH</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mt-10 flex justify-end">
        <button type="button" onClick={() => onClose()} style={{ fontSize: '12px' }} className="p-2 rounded font-bold text-white bg-red-500 mr-2">CANCEL</button>
        <button type="submit" disabled={isSubmitDisabled} className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50">
          {isEditMode ? 'Update Broadcast' : 'Create Broadcast'}
        </button>
      </div>
    </form>
  );
};

DeviceBroadcastCreate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

export default DeviceBroadcastCreate;
