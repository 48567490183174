import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { MdCancel, MdWaterDrop, MdThermostat, MdCheckCircle, MdBatteryFull, MdOutlineRemoveRedEye } from "react-icons/md";

const getRandomTemperature = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getRandomHumidity = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const DeviceShow = () => {
  const [storeList, setStoreList] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [storeFilter, setStoreFilter] = useState(null);
  const [filteredSensorData, setFilteredSensorData] = useState([]);

  const initializeData = () => {
    return [
        // Data untuk store VMG
        {
          device_id: "eb8ff9ca9156d99318txsv",
          name: "Sensor 1",
          online: 1,
          store: "PT. VMG",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 80 }
          ]
        },
        {
          device_id: "eb92bab38b810c971fo6kg",
          name: "Sensor 2",
          online: 1,
          store: "PT. VMG",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 60 }
          ]
        },
        {
          device_id: "eb8ff9ca9156d99318tuzv",
          name: "Sensor 3",
          online: 1,
          store: "PT. VMG",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 85 }
          ]
        },
        {
          device_id: "eb92bab38b810c971fo7lv",
          name: "Sensor 4",
          online: 1,
          store: "PT. VMG",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 90 }
          ]
        },
      
        // Data untuk store MAP
        {
          device_id: "eb8ff9ca9156d99318tqsv",
          name: "Sensor 1",
          online: 1,
          store: "PT. MAP",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 85 }
          ]
        },
        {
          device_id: "eb92bab38b810c971fo8kg",
          name: "Sensor 2",
          online: 1,
          store: "PT. MAP",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 75 }
          ]
        },
        {
          device_id: "eb8ff9ca9156d99318tusv",
          name: "Sensor 3",
          online: 1,
          store: "PT. MAP",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 65 }
          ]
        },
      
        // Data untuk store PKP
        {
          device_id: "eb8ff9ca9156d99318tcsv",
          name: "Sensor 1",
          online: 1,
          store: "PT. PKP",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 90 }
          ]
        },
        {
          device_id: "eb92bab38b810c971fo8vv",
          name: "Sensor 2",
          online: 1,
          store: "PT. PKP",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 50 }
          ]
        },
        {
          device_id: "eb8ff9ca9156d99318tuvv",
          name: "Sensor 3",
          online: 1,
          store: "PT. PKP",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 80 }
          ]
        },
        {
          device_id: "eb8ff9ca9156d99318tvw",
          name: "Sensor 4",
          online: 1,
          store: "PT. PKP",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 65 }
          ]
        },
        {
          device_id: "eb92bab38b810c971fo9nw",
          name: "Sensor 5",
          online: 1,
          store: "PT. PKP",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 70 }
          ]
        },
      
        // Data untuk store PDI
        {
          device_id: "eb8ff9ca9156d99318tpdi",
          name: "Sensor 1",
          online: 1,
          store: "PT. PDI",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 85 }
          ]
        },
        {
          device_id: "eb92bab38b810c971fpdi",
          name: "Sensor 2",
          online: 1,
          store: "PT. PDI",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 75 }
          ]
        },
        {
          device_id: "eb8ff9ca9156d99318tpdi3",
          name: "Sensor 3",
          online: 1,
          store: "PT. PDI",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 80 }
          ]
        },
        {
          device_id: "eb8ff9ca9156d99318tpdi4",
          name: "Sensor 4",
          online: 1,
          store: "PT. PDI",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 90 }
          ]
        },
      
        // Data untuk store MIC
        {
          device_id: "eb8ff9ca9156d99318tmic1",
          name: "Sensor 1",
          online: 1,
          store: "PT. MIC",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 80 }
          ]
        },
        {
          device_id: "eb92bab38b810c971fmic2",
          name: "Sensor 2",
          online: 1,
          store: "PT. MIC",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 60 }
          ]
        },
        {
          device_id: "eb8ff9ca9156d99318tmic3",
          name: "Sensor 3",
          online: 1,
          store: "PT. MIC",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 70 }
          ]
        },
        {
          device_id: "eb8ff9ca9156d99318tmic4",
          name: "Sensor 4",
          online: 1,
          store: "PT. MIC",
          status: [
            { code: 'va_temperature', value: getRandomTemperature(240, 260) },
            { code: 'va_humidity', value: getRandomHumidity(450, 650) },
            { code: 'battery_percentage', value: 85 }
          ]
        }
      
    ];
  };

  useEffect(() => {
    const data = initializeData();
    setSensorData(data);
    const stores = data.map(sensor => sensor.store);
    const uniqueStores = [...new Set(stores)];
    setStoreList(uniqueStores);
  }, []);

  useEffect(() => {
    filterSensorData();
  }, [searchTerm, storeFilter, sensorData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSensorData(prevData => prevData.map(sensor => ({
        ...sensor,
        status: sensor.status.map(stat => {
          if (stat.code === 'va_temperature') {
            return { ...stat, value: getRandomTemperature(240, 260) };
          }
          if (stat.code === 'va_humidity') {
            return { ...stat, value: getRandomHumidity(500, 700) };
          }
          return stat;
        })
      })));
      console.log("Updated sensor data:", sensorData);
    }, 60000);

    return () => clearInterval(interval);
  }, [sensorData]);

  const handleStoreFilterChange = (event) => {
    const selectedStore = event.target.value;
    setStoreFilter(selectedStore);
  };

  const filterSensorData = () => {
    let filteredData = sensorData;
    if (searchTerm.trim() !== "") {
      filteredData = filteredData.filter(sensor => sensor.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    if (storeFilter) {
      filteredData = filteredData.filter(sensor => sensor.store === storeFilter);
    }
    setFilteredSensorData(filteredData);
  };

  const renderStatusIcon = (online) => {
    return online === 1 ? (
      <div className="flex items-center">
        <MdCheckCircle className="h-5 w-5 mr-1 text-green-500"/>
        <p>Online</p>
      </div>
    ) : (
      <div className="flex items-center">
        <MdCancel className="h-5 w-5 mr-1 text-red-500"/>
        <p>Offline</p>
      </div>
    );
  };

  return (
    <div>
        <div className="dark:text-white">
          <div className="mt-2 flex">
            <input type="text" value={searchTerm} style={{ fontSize: '12px' }} placeholder="Search by Device Name ..." onChange={(e) => setSearchTerm(e.target.value)} className="p-2 border rounded flex-grow border-gray-500 dark:bg-navy-800"/>
            <select value={storeFilter || ''} onChange={handleStoreFilterChange} style={{ fontSize: '14px', width: '160px' }} className="p-2 ml-2 border rounded border-gray-500 dark:bg-navy-800">
              <option value="">All Stores</option>
              {storeList.map((store, index) => (<option key={index} value={store}>{store}</option>))}
            </select>
          </div>
          <div className="flex flex-wrap -m-1 mt-2">
            {filteredSensorData.length === 0 ? (
              <div className="p-5 text-center text-gray-500">No devices found.</div>
            ) : (
              filteredSensorData.map((sensor, index) => (
                <div className="w-full p-1 sm:w-1/2 md:w-1/3 lg:w-1/4" key={index}>
                  <div className="p-5 flex flex-col gap-3 bg-white shadow dark:bg-navy-800 rounded-lg">
                    <div className='flex justify-between flex-row gap-3'>
                      <div>
                        <div className="text-gray-500 text-sm">{sensor.store}</div>
                        <div className='font-bold'>{sensor.name}</div>
                      </div>
                      <div className='text-right'>
                        <div className="text-gray-500 text-sm">Status</div>
                        <div className="font-bold">{renderStatusIcon(sensor.online)}</div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <div className={`flex items-center ${sensor.status.find(stat => stat.code === 'va_temperature').value > 260 ? 'text-red-500' : ''}`}>
                        <div><MdThermostat className="h-5 w-5"/></div>
                        <div>{sensor.status.find(stat => stat.code === 'va_temperature').value / 10}°C</div>
                      </div>
                      <div className="flex items-center">
                        <div><MdWaterDrop className="h-5 w-5"/></div>
                        <div>{sensor.status.find(stat => stat.code === 'va_humidity').value / 10}%</div>
                      </div>
                      <div className="flex items-center">
                        <div><MdBatteryFull className="h-5 w-5"/></div>
                        <div>{sensor.status.find(stat => stat.code === 'battery_percentage').value}%</div>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <Link to={`/admin/show/${sensor.device_id}`}>
                        <button className="px-4 py-2 rounded-md flex items-center gap-2 hover:bg-opacity-90 text-white bg-brand-600"><MdOutlineRemoveRedEye /> Detail</button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
    </div>
  );
};

export default DeviceShow;
