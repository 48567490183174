import { fetchWithJwt } from "components/fetchWithJwt";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useState, useEffect, useCallback } from "react";

const Console = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countError, setCountError] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("");
  const [countSuccess, setCountSuccess] = useState(0);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [filterClicked, setFilterClicked] = useState(false);

  const handleResetClick = () => {
    setFilterType("");
    setFilterClicked(false);
  };

  const handleErrorClick = () => {
    setFilterType("ERROR");
    setFilterClicked(true);
  };

  const handleSuccessClick = () => {
    setFilterType("INFO");
    setFilterClicked(true);
  };

  const filterLogs = useCallback(() => {
    let filtered = logs;
    if (filterType) {
      filtered = filtered.filter((item) => item.level === filterType);
    }
    if (searchTerm.trim() !== "") {
      filtered = filtered.filter((item) => item.message.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    setFilteredLogs(filtered);
  }, [logs, filterType, searchTerm]);

  const formatDateTime = (dateString, type) => {
    if (!dateString) return "";
    const dateObj = new Date(dateString);
    const jakartaOffset = 7 * 60 * 60 * 1000;
    const utcTimestamp =
      dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000;
    const jakartaTimestamp = utcTimestamp + jakartaOffset;
    const jakartaDateObj = new Date(jakartaTimestamp);
    if (type === "date") {
      const day = jakartaDateObj.getDate().toString().padStart(2, "0");
      const month = (jakartaDateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = jakartaDateObj.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      const hours = jakartaDateObj.getHours().toString().padStart(2, "0");
      const minutes = jakartaDateObj.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    }
  };

  useEffect(() => {
    filterLogs();
  }, [searchTerm, logs, filterType, filterLogs]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchWithJwt(`${API_DOMAIN}/log/console`);
        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(
            `Failed to fetch data: ${response.status} - ${errorMessage}`
          );
        }
        const jsonData = await response.json();
        const successCount = jsonData.filter((log) => log.level === "INFO").length;
        const errorCount = jsonData.filter((log) => log.level === "ERROR").length;
        setCountSuccess(successCount);
        setCountError(errorCount);
        setLogs(jsonData);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, [API_DOMAIN]);

  return (
    <div className="mt-2 dark:border-white dark:bg-navy-900">
      <div className="flex text-black dark:text-white justify-start">
        <div onClick={handleSuccessClick}>
          <div className="rounded flex items-center border border-green-500 p-2 cursor-pointer">
            <MdCheckCircle style={{ color: "green", fontSize: "20px" }}/>
            <span className="ml-1 text-sm font-medium">Success Message:</span>
            <span className="ml-1 text-sm font-medium">{countSuccess.toString()}</span>
          </div>
        </div>
        <div className="ml-2" onClick={handleErrorClick}>
          <div className="rounded flex items-center border border-red-500 p-2 cursor-pointer">
            <MdCancel style={{ color: "red", fontSize: "20px" }}/>
            <span className="ml-1 text-sm font-medium">Error Message:</span>
            <span className="ml-1 text-sm font-medium">{countError.toString()}</span>
          </div>
        </div>
        {filterClicked && (
          <button onClick={handleResetClick} className="ml-2 p-1 border rounded text-sm font-medium border-blue-500 cursor-pointer">Reset</button>
        )}
      </div>
      <div>
        <div className="flex">
          <input type="text" value={searchTerm} style={{ fontSize: "12px" }} placeholder="Search by Message ..." onChange={(e) => setSearchTerm(e.target.value)} className="p-2 mt-2 border rounded flex-grow border-gray-500 dark:bg-navy-800 dark:text-white"/>
        </div>
        <div className="p-2 mt-2 hidden rounded md:flex text-white bg-brand-600 justify-start">
          <div style={{ width: "200px" }}>Date</div>
          <div style={{ width: "200px" }}>Time</div>
          <div style={{ width: "400px" }}>Message</div>
          <div style={{ width: "1000px" }}></div>
        </div>
        <div style={{ maxHeight: "calc(85vh - 200px)", overflowY: "auto" }}>
          {loading ? (
            <div className="text-center p-4 dark:text-white">Loading...</div>
          ) : filteredLogs.length === 0 ? (
            <div className="text-center p-4 dark:text-white">No message available</div>
          ) : (
            filteredLogs.map((item) => (
              <div key={item.id} className={`mt-2 p-3 border rounded md:flex justify-start border-gray-500 ${item.level === "ERROR" ? "bg-red-200" : "bg-green-200"}`}>
                <div style={{ width: "200px" }}>{formatDateTime(item.timestamp, "date")}</div>
                <div style={{ width: "200px" }}>{formatDateTime(item.timestamp, "time")}</div>
                <div style={{ width: "1000px" }}>{item.message}</div>
                <div style={{ width: "400px" }}></div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Console;
