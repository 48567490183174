import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdInfo, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { fetchWithJwt } from "components/fetchWithJwt";

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

const Password = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  
  const togglePasswordVisibility = (passwordType) => {
    if (passwordType === "current") {
      setCurrentPasswordVisible(!currentPasswordVisible);
    } else if (passwordType === "new") {
      setNewPasswordVisible(!newPasswordVisible);
    } else if (passwordType === "confirm") {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };

  const handleSubmit = async () => {
    try {
      if (newPassword !== confirmPassword) {
        setAlert({type: "error", message: "new password and confirm password do not match"});
        return;
      }
      const body = { currentPassword: currentPassword, newPassword: newPassword };
      const response = await fetchWithJwt(API_DOMAIN + "/user/change_password", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body),
      });
      const responseData = await response.json();
      if (!response.ok) {
        setAlert({type: "error", message: responseData.message});
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setAlert({type: "success", message: responseData.message});
      console.log("Password changed successfully:", responseData);
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  useEffect(() => {
    if (alert) {setTimeout(() => {setAlert(null)}, 5000)}
  }, [alert]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {alert && (
        <div className={`mb-4 ${alert.type === "success" ? "bg-green-100" : "bg-red-100"} border-l-4 border-${alert.type === "success" ? "green" : "red"}-500 text-${alert.type === "success" ? "green" : "red"}-700 rounded p-4`} role="alert">
          <MdInfo className="mr-2 inline"/>
          <label>{alert.message}</label>
        </div>
      )}
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-gray-900">Current Password</label>
            <div className="mt-2 relative">
              <input id="current-password" name="current-password" value={currentPassword} type={currentPasswordVisible ? "text" : "password"} onChange={(e) => setCurrentPassword(e.target.value)} className="block w-full rounded-md border-0 py-2 pl-2 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
              <button onClick={() => togglePasswordVisibility("current")} className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-600 hover:text-gray-900">{currentPasswordVisible ? <MdVisibilityOff/> : <MdVisibility/>}</button>
            </div>
          </div>
          <div>
            <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-gray-900">New Password</label>
            <div className="mt-2 relative">
              <input id="new-password" name="new-password" value={newPassword} type={newPasswordVisible ? "text" : "password"} onChange={(e) => setNewPassword(e.target.value)} className="block w-full rounded-md border-0 py-2 pl-2 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
              <button onClick={() => togglePasswordVisibility("new")} className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-600 hover:text-gray-900">{newPasswordVisible ? <MdVisibilityOff/> : <MdVisibility/>}</button>
            </div>
          </div>
          <div>
            <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">Confirm Password</label>
            <div className="mt-2 relative">
              <input id="confirm-password" name="confirm-password" value={confirmPassword} type={confirmPasswordVisible ? "text" : "password"} onChange={(e) => setConfirmPassword(e.target.value)} className="block w-full rounded-md border-0 py-2 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
              <button onClick={() => togglePasswordVisibility("confirm")} className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm text-gray-600 hover:text-gray-900">{confirmPasswordVisible ? <MdVisibilityOff/> : <MdVisibility/>}</button>
            </div>
          </div>
          <div>
            <button onClick={handleSubmit} className="flex w-full justify-center rounded-md bg-brand-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Change Password</button>
          </div>
          <div>
            <button onClick={() => navigate(-1)} className="flex w-full justify-center rounded-md bg-red-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
