import React, { useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import image from "assets/img/profile.png";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

const Header = (props) => {
  const navigate = useNavigate();
  const {onOpenSidenav, brandText} = props;
  const [nama, setNama] = React.useState(null);
  const [darkmode, setDarkmode] = React.useState(false);

  const handleLogout = () => {
    localStorage.removeItem("userData");
    navigate("/auth/login");
  }

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    setNama(JSON.parse(userData).name);
  },[]);

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link to="#" className="font-bold capitalize hover:text-navy-700 dark:hover:text-white">{brandText}{brandText === "Dashboard"}</Link>
        </p>
      </div>
      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white"/>
          </p>
          <input type="text" placeholder="Search..." className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"/>
        </div>
        <span onClick={onOpenSidenav} className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden">
          <FiAlignJustify className="h-5 w-5"/>
        </span>
        <div 
          className="cursor-pointer text-gray-600" 
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white"/>
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white"/>
          )}
        </div>
        <Dropdown
          classNames={"py-2 top-8 -left-[180px] w-max"}
          button={<img src={image} alt="" className="h-10 w-10 rounded-full"/>}
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <Link to="/admin/show"><p className="text-sm font-bold text-navy-700 dark:text-white">{nama}</p></Link>
                </div>
              </div>
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <Link to="/password" className="text-sm font-bold text-navy-700 dark:text-white">Password</Link>
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 "/>
              <div className="flex flex-col p-4">
                <a href="/" onClick={handleLogout} className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in">Log Out</a>
              </div>
            </div>
          }
        />
      </div>
    </nav>
  );
};
export default Header;
