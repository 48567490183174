import Setting from './setting';
import Card from 'components/card';
import PropTypes from 'prop-types';
import { fetchWithJwt } from 'components/fetchWithJwt';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { AreaChart, Area, Tooltip, CartesianGrid, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import { MdWaterDrop, MdThermostat, MdBatteryFull, MdSettings, MdLocationPin, MdBarChart, MdArrowCircleLeft, MdArrowCircleRight } from 'react-icons/md';

const Detail = () => {
  const [error, setError] = useState(null);
  const [device, setDevice] = useState({});
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState('hourly');
  const [layout, setLayout] = useState('history');
  const [sensorData, setSensorData] = useState([]);
  const [notifStatus, setNotifStatus] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);

  const navigate = useNavigate();
  const { id: selectedDeviceId } = useParams();
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const isToday = currentDate === new Date().toISOString().split('T')[0];

  const handleNextDay = () => {
    const date = new Date(currentDate);
    if (period === 'daily') {
      date.setMonth(date.getMonth() + 1);
    } else if (period === 'hourly') {
      date.setDate(date.getDate() + 1);
    }
    setCurrentDate(date.toISOString().split('T')[0]);
  };
  
  const handlePreviousDay = () => {
    const date = new Date(currentDate);
    if (period === 'daily') {
      date.setMonth(date.getMonth() - 1);
    } else if (period === 'hourly') {
      date.setDate(date.getDate() - 1);
    }
    setCurrentDate(date.toISOString().split('T')[0]);
  };

  const processData = useCallback((data) => {
    return data.map(sensor => {
      const formattedTime = period === 'daily'
        ? new Date(sensor.tanggal).toLocaleDateString('id-ID', {day: '2-digit', month: '2-digit'})
        : sensor.jam;
      return {
        time: formattedTime,
        temperature: (sensor.temperature / 10).toFixed(1),
        humidity: (sensor.humidity / 10).toFixed(1),
        name: sensor.name
      };
    });
  }, [period]);

  const fetchDeviceDetail = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/detail/${selectedDeviceId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const { data_alat } = await response.json();
      setDevice(data_alat);
      setNotifStatus(data_alat.notif);
    } catch (error) {
      setError('Failed to fetch device details');
    }
  };  

  const fetchData = async (date, period) => {
    try {
      const [year, month] = date.split('-');
      const url = period === 'daily' ? `${API_DOMAIN}/monitor/data_daily` : `${API_DOMAIN}/monitor/data_hourly`;
      const response = await fetchWithJwt(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({id: selectedDeviceId, date, month: month.padStart(2, '0'), year}),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setSensorData(processData(data));
    } catch (error) {
      setError(`Failed to fetch data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData(currentDate, period);
    fetchDeviceDetail();
  }, [currentDate, period, processData, selectedDeviceId], notifStatus);

  useEffect(() => {
    fetchDeviceDetail();
    const intervalId = setInterval(fetchDeviceDetail, 60000);
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return (
      <Card extra="mt-5 items-center">
        <div className="text-xl font-bold text-navy-700 dark:text-white">Loading...</div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card extra="mt-5 items-center">
        <div className="text-xl font-bold text-navy-700 dark:text-white">{error}</div>
      </Card>
    );
  }

  return (
    <div>
      <Card className="mt-5 bg-white dark:bg-navy-700 dark:text-white p-5 rounded-xl">
        <div className="text-center">
          {device.icon && (
            <div className="text-xl font-bold text-navy-700 dark:text-white flex justify-center">
              <img className="h-20 w-20" alt="Device Icon" src={`https://images.tuyaus.com/${device.icon}`} />
            </div>
          )}
          <div className="text-xl font-bold text-blue-500 dark:text-white">{device.name}</div>
          <div className="flex mt-5 items-center justify-center space-x-10">
            {device.status && (
              <>
                <DeviceStatusIcon icon={MdThermostat} value={`${device.status.find(stat => stat.code === 'va_temperature')?.value / 10}°C`} />
                <DeviceStatusIcon icon={MdWaterDrop} value={`${device.status.find(stat => stat.code === 'va_humidity')?.value / 10}%`} />
                <DeviceStatusIcon icon={MdBatteryFull} value={`${device.status.find(stat => stat.code === 'battery_percentage')?.value}%`} />
              </>
            )}
          </div>
        </div>
          <>
            <LayoutTabs layout={layout} setLayout={setLayout} />
            
            {layout === 'history' && (
              <>
              <PeriodTabs period={period} setPeriod={setPeriod} />
              <div className="flex items-center justify-between mt-5">
                <button onClick={handlePreviousDay} className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md flex items-center">
                  <MdArrowCircleLeft className="mr-2" /> Previous
                </button>
                <span className="text-xl font-bold text-gray-700 dark:text-white">
                  {period === 'hourly'
                    ? new Date(currentDate).toLocaleDateString('id-ID', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })
                    : new Date(currentDate).toLocaleDateString('id-ID', {
                        month: 'long',
                        year: 'numeric',
                      })}
                </span>
                <button disabled={isToday} onClick={handleNextDay} className={`px-4 py-2 rounded-md ${isToday ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'} text-white flex items-center`}>
                  <MdArrowCircleRight className="mr-2" /> Next
                </button>
              </div>
              </>
            )}
            {layout === 'history' && <ChartSection chartData={sensorData} />}
            {layout === 'maps' && <MapsSection device={device} />}
            {layout === 'notif' && <Setting notifStatus={notifStatus} setNotifStatus={setNotifStatus} deviceId={selectedDeviceId} />}
          </>
        <CloseButton />
      </Card>
    </div>
  );
};

const DeviceStatusIcon = ({ icon: Icon, value }) => (
  <div className="flex items-center">
    <Icon className="h-5 w-5" />
    <div>{value}</div>
  </div>
);

const LayoutTabs = ({ layout, setLayout }) => (
  <div className="flex items-center justify-center mt-5">
    <LayoutTabButton layout={layout} setLayout={setLayout} target="history" icon={MdBarChart} label="Data" />
    <LayoutTabButton layout={layout} setLayout={setLayout} target="maps" icon={MdLocationPin} label="Maps" />
    <LayoutTabButton layout={layout} setLayout={setLayout} target="notif" icon={MdSettings} label="Setting" />
  </div>
);

const LayoutTabButton = ({ layout, setLayout, target, icon: Icon, label }) => (
  <button onClick={() => setLayout(target)} className={`text-center flex items-center justify-center gap-1 w-full py-2 ${layout === target ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>
    <Icon className="h-5 w-5" />
    <span>{label}</span>
  </button>
);

const PeriodTabs = ({ period, setPeriod }) => (
  <div className="flex items-center justify-center mt-5">
    <PeriodTabButton period={period} setPeriod={setPeriod} target="hourly" label="Day" />
    <PeriodTabButton period={period} setPeriod={setPeriod} target="daily" label="Month" />
  </div>
);

const PeriodTabButton = ({ period, setPeriod, target, label }) => (
  <button onClick={() => setPeriod(target)} className={`text-center w-full py-2 ${period === target ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>{label}</button>
);

const ChartSection = ({ chartData }) => (
  <>
    <ChartContainer color="#8884d8" domain={[20, 40]} title="Temperature" dataKey="temperature" chartData={chartData} />
    <ChartContainer color="#03b575" domain={[50, 70]} title="Humidity" dataKey="humidity" chartData={chartData} />
  </>
);

const ChartContainer = ({ color, domain, title, dataKey, chartData }) => (
  <div style={{ display: 'flex', marginTop: '1.25rem' }}>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div style={{position: 'absolute', transform: 'rotate(-90deg)'}}>{title}</div>
    </div>
    <div style={{ flexGrow: 1 }}>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time">
            <Label value="Time" position="bottom" />
          </XAxis>
          <YAxis domain={domain}/>
          <Tooltip />
          <Area type="monotone" dataKey={dataKey} stroke={color} fill={color} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const MapsSection = ({ device }) => (
  <div className="flex mt-5 w-full justify-center">
    <iframe width="600" height="450" className="w-full mt-5" src={`https://www.google.com/maps?q=${device.lat},${device.lon}&hl=es;z=14&output=embed`} />
  </div>
);

const CloseButton = () => {
  const navigate = useNavigate();
  return (
    <div className="flex mt-2 w-full justify-end p-4">
      <button onClick={() => navigate(-1)} className="px-4 mt-5 py-2 hover:bg-opacity-80 bg-gray-600 rounded-md">Close</button>
    </div>
  );
};

DeviceStatusIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
  value: PropTypes.string.isRequired,
};

LayoutTabs.propTypes = {
  layout: PropTypes.string.isRequired,
  setLayout: PropTypes.func.isRequired,
};

LayoutTabButton.propTypes = {
  layout: PropTypes.string.isRequired,
  setLayout: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
};

PeriodTabs.propTypes = {
  period: PropTypes.string.isRequired,
  setPeriod: PropTypes.func.isRequired,
};

PeriodTabButton.propTypes = {
  period: PropTypes.string.isRequired,
  setPeriod: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

ChartSection.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string.isRequired,
      temperature: PropTypes.string.isRequired,
      humidity: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

ChartContainer.propTypes = {
  color: PropTypes.string.isRequired,
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  title: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string.isRequired,
      temperature: PropTypes.string.isRequired,
      humidity: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  ticks: PropTypes.arrayOf(PropTypes.number).isRequired,
};

MapsSection.propTypes = {
  device: PropTypes.object.isRequired,
};

export default Detail;
