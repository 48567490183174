import { fetchWithJwt } from "components/fetchWithJwt";
import { useState, useEffect, useCallback } from 'react';
import DeviceControlRemote from "views/deviceControl/deviceControlRemote";
import { MdCancel, MdCheckCircle, MdOutlineRemoveRedEye } from "react-icons/md";

const DeviceControl = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
  const DisplayMode = { TABLE: 'table', DETAIL: 'detail' };

  const [storeList, setStoreList] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [display, setDisplay] = useState(DisplayMode.TABLE);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [filteredSensorData, setFilteredSensorData] = useState([]);
  const [selectedDeviceName, setSelectedDeviceName] = useState(null);
  const [selectedDeviceDescription, setSelectedDeviceDescription] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('company_id') || "");

  const fetchStoreData = async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/store`);
      if (!response.ok) throw new Error(`Failed to fetch data: ${response.status} - ${response.statusText}`);
      const data = await response.json();
      setStoreList(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDetailClick = useCallback((deviceId, name, description) => {
    setSelectedDeviceId(deviceId);
    setSelectedDeviceName(name);
    setSelectedDeviceDescription(description);
    setDisplay(DisplayMode.DETAIL);
  }, []);

  const renderStatusIcon = (online) => {
    return online === 1 ? (
      <div className="flex items-center">
        <MdCheckCircle className="h-5 w-5 mr-1 text-green-500"/>
        <p>Online</p>
      </div>
    ) : (
      <div className="flex items-center">
        <MdCancel className="h-5 w-5 mr-1 text-red-500"/>
        <p>Offline</p>
      </div>
    );
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchWithJwt(`${API_DOMAIN}/device/show_control`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      let filteredData = data.data_alat;
      if (selectedCompany && selectedCompany !== "All Company") {
        filteredData = filteredData.filter(sensor => sensor.id_company === parseInt(selectedCompany));
      }
      setSensorData(filteredData);
      setFilteredSensorData(filteredData);
    } catch (error) {
      console.error(error);
    }
  }, [API_DOMAIN, selectedCompany]);

  useEffect(() => {
    fetchStoreData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedCompany]);

  useEffect(() => {
    const filteredBySearchTerm = sensorData.filter(sensor =>
      sensor.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSensorData(filteredBySearchTerm);
  }, [searchTerm, sensorData]);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedCompany = localStorage.getItem('company_id');
      if (updatedCompany !== selectedCompany) {setSelectedCompany(updatedCompany)}
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {window.removeEventListener('storage', handleStorageChange)};
  }, [selectedCompany]);

  return (
    <div className="dark:text-white">
      {display === DisplayMode.TABLE && (
        <div>
          <div className="mt-2 flex">
            <input type="text" value={searchTerm} style={{ fontSize: '12px' }} placeholder="Search by Device Name ..." onChange={(e) => setSearchTerm(e.target.value)} className="p-2 border rounded flex-grow border-gray-500 dark:bg-navy-800"/>
          </div>
          <div className="flex flex-wrap -m-1 mt-2">
            {filteredSensorData.map((sensor) => (
              <div key={sensor.device_id} className="w-full p-1 sm:w-1/2 md:w-1/3 lg:w-1/4">
                <div className="p-5 flex flex-col gap-3 bg-white shadow dark:bg-navy-800 rounded-lg">
                  <div className="flex justify-between flex-row gap-3">
                    <div>
                      <div className="text-gray-500 text-sm">{sensor.store}</div>
                      <div className="font-bold">{sensor.name}</div>
                    </div>
                    <div className="text-right">
                      <div className="text-gray-500 text-sm">Status</div>
                      <div className="font-bold">{renderStatusIcon(sensor.online)}</div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button onClick={() => handleDetailClick(sensor.device_id, sensor.name, sensor.deskripsi)} className="px-4 py-2 rounded-md flex items-center gap-2 hover:bg-opacity-90 text-white bg-brand-600">
                      <MdOutlineRemoveRedEye /> Detail
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {display === DisplayMode.DETAIL && selectedDeviceId && (
        <DeviceControlRemote deviceId={selectedDeviceId} name={selectedDeviceName} description={selectedDeviceDescription} setDisplay={setDisplay} />
      )}
    </div>
  );
};

export default DeviceControl;
