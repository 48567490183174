import Table from 'components/table';
import React, { useState, useEffect } from 'react';
import { fetchWithJwt } from 'components/fetchWithJwt';

const Store = () => {
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const [storeData, setStoreData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchDataStore = async () => {
      try {
        const response = await fetchWithJwt(`${API_DOMAIN}/store`);
        if (response.ok) {
          const data = await response.json();
          setStoreData(data);
        } else {
          throw new Error('Failed to fetch store data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchDataStore();
    const intervalId = setInterval(fetchDataStore, 60000);
    return () => clearInterval(intervalId);
  }, [API_DOMAIN]);

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'nama', headerName: 'Store Name' },
    { field: 'lokasi', headerName: 'Store Address' },
  ];

  const filteredItems = storeData.filter(item => item.nama && item.nama.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="bg-white dark:bg-navy-900 dark:text-white dark:border-white p-4 rounded-md">
      <div className="mt-2 flex">
        <input type="text" value={searchTerm} style={{ fontSize: '12px' }} placeholder="Search by Device Name ..." onChange={e => setSearchTerm(e.target.value)} className="p-2 border rounded flex-grow border-gray-500 dark:bg-navy-800"/>
      </div>
      <div className="mb-4" />
      <Table columns={columns} data={filteredItems} />
    </div>
  );
};

export default Store;
