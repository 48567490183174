import Card from 'components/card';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MdWaterDrop, MdThermostat, MdBatteryFull } from 'react-icons/md';
import { Label, Line, XAxis, YAxis, Tooltip, LineChart, CartesianGrid, ResponsiveContainer } from 'recharts';

const generateDayData = (date) => {
  const baseData = [
    { jam: '00:00', temperature: 24.5, humidity: 50 },
    { jam: '01:00', temperature: 24.7, humidity: 52 },
    { jam: '02:00', temperature: 24.8, humidity: 53 },
    { jam: '03:00', temperature: 24.6, humidity: 51 },
    { jam: '04:00', temperature: 24.5, humidity: 50 },
    { jam: '05:00', temperature: 24.7, humidity: 52 },
    { jam: '06:00', temperature: 24.9, humidity: 54 },
    { jam: '07:00', temperature: 25.1, humidity: 55 },
    { jam: '08:00', temperature: 25.3, humidity: 57 },
    { jam: '09:00', temperature: 25.5, humidity: 58 },
    { jam: '10:00', temperature: 25.7, humidity: 60 },
    { jam: '11:00', temperature: 25.8, humidity: 62 },
    { jam: '12:00', temperature: 25.6, humidity: 61 },
    { jam: '13:00', temperature: 25.5, humidity: 60 },
    { jam: '14:00', temperature: 25.3, humidity: 58 },
    { jam: '15:00', temperature: 25.1, humidity: 56 },
    { jam: '16:00', temperature: 24.9, humidity: 54 },
    { jam: '17:00', temperature: 24.7, humidity: 52 },
    { jam: '18:00', temperature: 24.6, humidity: 51 },
    { jam: '19:00', temperature: 24.5, humidity: 50 },
    { jam: '20:00', temperature: 24.6, humidity: 51 },
    { jam: '21:00', temperature: 24.7, humidity: 52 },
    { jam: '22:00', temperature: 24.8, humidity: 53 },
    { jam: '23:00', temperature: 24.9, humidity: 54 },
  ];
  return baseData.map(entry => ({
    tanggal: date,
    ...entry,
  }));
};

const Detail = () => {
  const navigate = useNavigate();
  const [device] = useState({
    name: "Device Name",
    status: [
      { code: 'va_temperature', value: 220 },
      { code: 'va_humidity', value: 550 },
      { code: 'battery_percentage', value: 80 },
    ],
  });

  const [dayData, setDayData] = useState([]);
  const [period, setPeriod] = useState('day');
  const { id: selectedDeviceId } = useParams();
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const isToday = currentDate === new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (period === 'day') {
      const todayDate = new Date().toISOString().split('T')[0];
      setCurrentDate(todayDate);
      setDayData(generateDayData(todayDate));
    }
  }, [period]);

  useEffect(() => {
    const updateFilteredData = () => {
      const now = new Date();
      const currentHour = now.getHours();
      setDayData(prevDayData => prevDayData.filter(entry => {
        const entryHour = parseInt(entry.jam.split(':')[0], 10);
        return entryHour <= currentHour;
      }));
    };
    updateFilteredData();
    const intervalId = setInterval(updateFilteredData, 60 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [currentDate]);

  const filteredData = period === 'day' ? dayData.filter(sensor => sensor.tanggal === currentDate) : [];

  if (!device) {
    return (
      <Card extra="mt-5 items-center">
        <div className="text-xl font-bold text-navy-700 dark:text-white">Loading...</div>
      </Card>
    );
  }

  return (
    <div>
      <Card className="mt-5 bg-white dark:bg-navy-700 dark:text-white p-5 rounded-xl">
        <div className="text-center">
          <div className="text-xl font-bold text-blue-500 dark:text-white">{device.name}</div>
          <div className="flex mt-5 items-center justify-center space-x-10">
            {device.status && (
              <>
                <DeviceStatusIcon icon={MdThermostat} value={`${device.status.find(stat => stat.code === 'va_temperature')?.value / 10}°C`} />
                <DeviceStatusIcon icon={MdWaterDrop} value={`${device.status.find(stat => stat.code === 'va_humidity')?.value / 10}%`} />
                <DeviceStatusIcon icon={MdBatteryFull} value={`${device.status.find(stat => stat.code === 'battery_percentage')?.value}%`} />
              </>
            )}
          </div>
        </div>
        {filteredData.length > 0 && (
          <>
            <ChartSection chartData={filteredData} />
          </>
        )}
        {filteredData.length === 0 && (
          <div className="mt-5 text-center text-xl font-bold text-gray-500 dark:text-white">No Data Available</div>
        )}
        <CloseButton />
      </Card>
    </div>
  );
};

const DeviceStatusIcon = ({ icon: Icon, value }) => (
  <div className="flex items-center">
    <Icon className="h-5 w-5" />
    <div>{value}</div>
  </div>
);

const ChartSection = ({ chartData }) => (
  <>
    <ChartContainer color="#8884d8" domain={[20, 30]} title="Temperature" dataKey="temperature" chartData={chartData} ticks={[20, 22, 24, 26, 28, 30]} />
    <ChartContainer color="#03b575" domain={[45, 70]} title="Humidity" dataKey="humidity" chartData={chartData} ticks={[45, 50, 55, 60, 65, 70]} />
  </>
);

const ChartContainer = ({ color, domain, title, dataKey, chartData, ticks }) => (
  <Card className="mt-5 bg-white dark:bg-navy-700 dark:text-white rounded-xl">
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData} margin={{ top: 10, right: 10, left: -30, bottom: 10 }}>
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" />
          <Line type="monotone" dataKey={dataKey} stroke={color} />
          <XAxis dataKey="jam" tickFormatter={(value) => value}>
          <Label value={title} offset={0} position="insideBottom" style={{fill: color, fontSize: '14px', fontWeight: 'bold', textAnchor: 'middle'}} />
          </XAxis>
          <YAxis domain={domain}>
          </YAxis>
        </LineChart>
      </ResponsiveContainer>
    </div>
  </Card>
);

const CloseButton = () => {
  const navigate = useNavigate();
  return (
    <div className="flex mt-2 w-full justify-end p-4">
      <button onClick={() => navigate(-1)} className="px-4 mt-5 py-2 hover:bg-opacity-80 bg-gray-600 rounded-md">Close</button>
    </div>
  );
};

export default Detail;
